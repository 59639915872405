// import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';

/// meterial ///
import { MaterialModule } from '../material-ui/material-ui.module';
import { LogoComponent } from './logo/logo.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SearchComponent } from './search/search.component';
import { GamesSliderComponent } from './games-slider/games-slider.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { RegisterComponent } from './register/register.component';
import { DataTableModule } from '@pascalhonegger/ng-datatable';

import { ModalFooterComponent } from './modal/modal-footer/modal-footer.component';
import { AutosizeModule } from 'ngx-autosize';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { IndexRadialComponent } from './index-radial/index-radial.component';
import { SafePipe } from '../pipes/safe.pipe';
import { ChatUsersComponent } from './chat-users/chat-users.component';
import { UnInfoCardComponent } from './un-info-card/un-info-card.component';
import { UnFileUploadComponent } from './un-file-upload/un-file-upload.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { UnUserDashboadrViewComponent } from './un-user-dashboadr-view/un-user-dashboadr-view.component';
import { UnMagicButtonComponent } from './un-buttons/un-magic-button/un-magic-button.component';
import { ImageDialogData, TableComponent } from './table/table.component';
import { TableFormComponent } from './table/table-form/table-form.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LevelPipe } from '../pipes/level.pipe';
import { UnGameCardComponent } from './un-game-card/un-game-card.component';
import { UnSkillCardComponent } from './un-skill-card/un-skill-card.component';
import { UnGameLoaderComponent } from './un-game-loader/un-game-loader.component';
import { UnFriendsBarComponent } from './un-friends-bar/un-friends-bar.component';
import { UnCreatePartyComponent } from './un-create-party/un-create-party.component';
import { DashboadrLevelViewComponent } from './un-user-dashboadr-view/dashboard-level-view/dashboard-level-view.component';
import { Nl2brPipe } from '../pipes/nl2br.pipe';
import { FriendsBarHeaderComponent } from './un-friends-bar/friends-bar-header/friends-bar-header.component';
import { FriendsListComponent } from './un-friends-bar/friends-list/friends-list.component';
import { UnUsersGroupViewComponent } from './un-users-group-view/un-users-group-view.component';
import { SendInviteComponent } from './send-invite/send-invite.component';
import { SignUpWrapperComponent } from './register/sign-up-wrapper/sign-up-wrapper.component';
import { SignUpStudentMentorComponent } from './register/sign-up-student-mentor/sign-up-student-mentor.component';
import { SignUpTeacherComponent } from './register/sign-up-teacher/sign-up-teacher.component';
import { ProgressSlideComponent } from './progress-slide/progress-slide.component';
import { PaginatorComponent } from './table-paginator/paginator.component';

import { TranslateModule } from '@ngx-translate/core';
import { ChatIframeComponent } from './chat-users/chat-iframe/chat-iframe.component';
import { FriendsRequestsListComponent } from './un-friends-bar/friends-requests-list/friends-requests-list.component';
import { FindFriendsListComponent } from './un-friends-bar/find-friends-list/find-friends-list.component';
import { UnHeaderButtonComponent } from './un-header-button/un-header-button.component';
import { UnTabsComponent } from './un-tabs/un-tabs.component';
import { UnTabComponent } from './un-tabs/un-tab/un-tab.component';
import { UnBackButtonComponent } from './un-buttons/un-back-button/un-back-button.component';
import { UnSaveButtonComponent } from './un-buttons/un-save-button/un-save-button.component';
import { UnSelectComponent } from './un-select/un-select.component';
import { UnCreateButtonComponent } from './un-buttons/un-create-button/un-create-button.component';
import { UnPageSubPanelComponent } from './un-page-sub-panel/un-page-sub-panel.component';
import { TeamGeneratorComponent } from './team-generator/team-generator.component';
import { UnImageUploadComponent } from './un-image-upload/un-image-upload.component';
import { UnRadioSetComponent } from './un-radio-set/un-radio-set.component';
import { UnLangSwitchComponent } from './un-lang-switch/un-lang-switch.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { UnAvatarComponent } from './un-avatar/un-avatar.component';
import { UnSeeAllComponent } from './un-see-all/un-see-all.component';
import { UnMenuComponent } from './un-menu/un-menu.component';
import { UnMenuItemComponent } from './un-menu/un-menu-item/un-menu-item.component';
import { UnChangeMentorComponent } from './change-mentor/un-change-mentor.component';
import { UnGradientTextComponent } from './un-gradient-text/un-gradient-text.component';
import { ConfirmationDialog } from './confirm/confirmation-dialog.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { MenuModule } from '../menu/menu.module';
import { UnUserRowComponent } from './un-user-row/un-user-row.component';
import { NoDataComponent } from './no-data/no-data.component';
import { TeamChatComponent } from './chat/team-chat.component';
import { UnInfoIconComponent } from './un-info-icon/un-info-icon.component';
import { SearchPipe } from '../pipes/seach.pipe';
import { ResizableDraggableComponent } from './resizable-draggable/resizable-draggable.component';
import { FreeDraggingHandleDirective } from '../directives/free-dragging-handle.directive';
import { FreeDraggingDirective } from '../directives/free-dragging.directive';
import { TeamDriveComponent } from '../components/communications/team-drive/team-drive.component';
import { DriveComponent } from './drive/drive.component';
import { ChatComponent } from './chat/chat/chat.component';
import { UDatePipe } from '../pipes/udate.pipe';
import { CardComponent } from './card/card';
import { NotificationComponent } from './notification/notification.component';
import { TimeLeftTranslatePipe } from '../pipes/time-left-translate.pipe';
import { LoginCardComponent } from './login/login-card/login-card.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { TutorialComponent } from './tutorial/tutorial';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ScrollableDirective } from '../directives/scrollable.directive';
import { UnGptChatButtonComponent } from './un-gpt-chat-button/un-gpt-chat-button.component';
import { UniChatComponent } from './chat/uni-chat/uni-chat.component';
import { ChipComponent } from './un-chip/chip';
import { CodeToPrePipe } from '../pipes/code.pipe';
import { ResizableDirective } from '../directives/resizable.directive';
import { FavoriteComponent } from './drive/favorite/favorite';
import { NotificatorComponent } from './un-notificator/notificator';
import { AutofocusDirective } from '../directives/autofocus.directive';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs';
import { TagComponent } from './tag/tag';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tabs/tab/tab.component';
import { PaginationComponent } from './pagination/pagination.component';
import { TextareaComponent } from './form-inputs/textarea/textarea.component';
import { TextInputComponent } from './form-inputs/text-input/text-input.component';
import { DateInputComponent } from './form-inputs/date-input/date-input.component';
import { SelectComponent } from './form-inputs/select/select.component';
import { TableFiltersComponent } from './table/table-filters/table-filters.component';
import { ChartLoaderComponent } from './chart-loader/chart-loader.component';
import { NumberAnimationPipe } from '../pipes/number-animation.pipe';
import { NumberAnimationDirective } from '../directives/number-animation.directive';
import { CheckboxInputComponent } from './form-inputs/checkbox-input/checkbox-input.component';
import { CheckboxArrayInputComponent } from './form-inputs/checkbox-array-input/checkbox-array-input';
// import { NgxGlideModule } from 'ngx-glide';

@NgModule({
    imports: [
        CommonModule,
        MatMomentDateModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DataTableModule,
        AutosizeModule,
        MenuModule,
        NgxFileDragDropModule,
        TranslateModule,
        // NgxGlideModule,
    ],
    declarations: [
        LayoutComponent,
        HeaderComponent,
        LogoComponent,
        SidebarComponent,
        SearchComponent,
        GamesSliderComponent,
        LoginComponent,
        NavbarComponent,
        RegisterComponent,
        IndexRadialComponent,
        SafePipe,
        LevelPipe,
        ChatUsersComponent,
        ChatIframeComponent,
        UnInfoCardComponent,
        UnFileUploadComponent,
        UnUserDashboadrViewComponent,
        UnMagicButtonComponent,
        ImageDialogData,
        TableComponent,
        TableFormComponent,
        ConfirmDialogComponent,
        UnGameCardComponent,
        UnSkillCardComponent,
        // FcbModalComponent,
        // FcbModalHeaderComponent,
        ModalFooterComponent,
        UnGameLoaderComponent,
        UnFriendsBarComponent,
        UnCreatePartyComponent,
        DashboadrLevelViewComponent,
        // FcbFileUploadComponent,
        TruncatePipe,
        Nl2brPipe,
        SearchPipe,
        UDatePipe,
        CodeToPrePipe,
        FriendsBarHeaderComponent,
        FriendsRequestsListComponent,
        FriendsListComponent,
        FindFriendsListComponent,
        UnUsersGroupViewComponent,
        SendInviteComponent,
        SignUpWrapperComponent,
        SignUpStudentMentorComponent,
        SignUpTeacherComponent,
        ProgressSlideComponent,
        UnHeaderButtonComponent,
        PaginatorComponent,
        UnTabsComponent,
        UnTabComponent,
        UnBackButtonComponent,
        UnSaveButtonComponent,
        UnSelectComponent,
        UnCreateButtonComponent,
        UnPageSubPanelComponent,
        TeamGeneratorComponent,
        UnImageUploadComponent,
        UnRadioSetComponent,
        UnLangSwitchComponent,
        ProgressBarComponent,
        UnAvatarComponent,
        UnSeeAllComponent,
        UnMenuComponent,
        UnMenuItemComponent,
        UnChangeMentorComponent,
        UnGradientTextComponent,
        ConfirmationDialog,
        DateRangePickerComponent,
        UnUserRowComponent,
        NoDataComponent,
        TeamChatComponent,
        UnInfoIconComponent,
        ResizableDraggableComponent,
        FreeDraggingDirective,
        ResizableDirective,
        FreeDraggingHandleDirective,
        TeamDriveComponent,
        DriveComponent,
        ChatComponent,
        CardComponent,
        NotificationComponent,
        TimeLeftTranslatePipe,
        LoginCardComponent,
        ForgotPasswordComponent,
        TutorialComponent,
        ResetPasswordComponent,
        ScrollableDirective,
        UnGptChatButtonComponent,
        UniChatComponent,
        ChipComponent,
        FavoriteComponent,
        NotificatorComponent,
        AutofocusDirective,
        BreadcrumbsComponent,
        TagComponent,
        TabsComponent,
        TabComponent,
        PaginationComponent,
        TextareaComponent,
        TextInputComponent,
        DateInputComponent,
        SelectComponent,
        TableFiltersComponent,
        ChartLoaderComponent,
        NumberAnimationPipe,
        NumberAnimationDirective,
        CheckboxInputComponent,
        CheckboxArrayInputComponent,
    ],
    exports: [
        LayoutComponent,
        LogoComponent,
        LogoComponent,
        SearchComponent,
        GamesSliderComponent,
        LoginComponent,
        NavbarComponent,
        RegisterComponent,
        IndexRadialComponent,
        SafePipe,
        LevelPipe,
        Nl2brPipe,
        SearchPipe,
        UDatePipe,
        CodeToPrePipe,
        ChatUsersComponent,
        ChatIframeComponent,
        UnInfoCardComponent,
        UnFileUploadComponent,
        ImageDialogData,
        UnUserDashboadrViewComponent,
        UnMagicButtonComponent,
        TableComponent,
        TableFormComponent,
        ConfirmDialogComponent,
        UnGameCardComponent,
        DashboadrLevelViewComponent,
        UnSkillCardComponent,
        UnHeaderButtonComponent,
        ModalFooterComponent,
        UnFriendsBarComponent,
        UnGameLoaderComponent,
        UnCreatePartyComponent,
        TruncatePipe,
        FriendsBarHeaderComponent,
        FriendsListComponent,
        FriendsRequestsListComponent,
        FindFriendsListComponent,
        UnUsersGroupViewComponent,
        SendInviteComponent,
        SignUpWrapperComponent,
        SignUpStudentMentorComponent,
        SignUpTeacherComponent,
        ProgressSlideComponent,
        PaginatorComponent,
        UnTabsComponent,
        UnTabComponent,
        UnBackButtonComponent,
        UnSaveButtonComponent,
        UnSelectComponent,
        UnCreateButtonComponent,
        UnPageSubPanelComponent,
        TeamGeneratorComponent,
        UnImageUploadComponent,
        UnRadioSetComponent,
        UnLangSwitchComponent,
        ProgressBarComponent,
        UnAvatarComponent,
        UnSeeAllComponent,
        UnMenuComponent,
        UnMenuItemComponent,
        UnChangeMentorComponent,
        UnGradientTextComponent,
        ConfirmationDialog,
        DateRangePickerComponent,
        UnUserRowComponent,
        NoDataComponent,
        TeamChatComponent,
        UnInfoIconComponent,
        ResizableDraggableComponent,
        FreeDraggingDirective,
        FreeDraggingHandleDirective,
        ResizableDirective,
        TeamDriveComponent,
        DriveComponent,
        NotificationComponent,
        ChatComponent,
        CardComponent,
        TimeLeftTranslatePipe,
        LoginCardComponent,
        ForgotPasswordComponent,
        TutorialComponent,
        ResetPasswordComponent,
        ScrollableDirective,
        UnGptChatButtonComponent,
        UniChatComponent,
        ChipComponent,
        FavoriteComponent,
        NotificatorComponent,
        AutofocusDirective,
        BreadcrumbsComponent,
        TagComponent,
        TabsComponent,
        TabComponent,
        TextareaComponent,
        TextInputComponent,
        DateInputComponent,
        SelectComponent,
        TableFiltersComponent,
        ChartLoaderComponent,
        NumberAnimationPipe,
        NumberAnimationDirective,
        CheckboxInputComponent,
        CheckboxArrayInputComponent,
        PaginationComponent,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    ],
})
export class UiModule {}
