<div *ngIf="search || filters.length > 0" class="print-none mb-4 flex items-center justify-end gap-x-6 px-4">
    <div class="text-lg font-medium">{{ title }}</div>
    <app-table-filters [filters]="filters" (filtersChanged)="filtersChanged($event)"> </app-table-filters>

    <!-- Search -->
    <div *ngIf="search">
        <app-text-input
            placeholder="Search"
            [formControl]="searchText"
            [clear]="true"
            (keyup)="searchInput($event)"
            (inputCleared)="onSearch.emit('')"
        >
        </app-text-input>
    </div>
</div>

<div class="table-responsive">
    <table
        class="table-striped table w-full"
        [ngClass]="{ slim: slim }"
        #dataTable="mfDataTable"
        [mfData]="tableData"
        [mfRowsOnPage]="pagination ? itemsPerPage : data.length"
    >
        <thead *ngIf="showHeader">
            <tr>
                <th *ngIf="select" width="40" class="p-1"></th>

                <ng-container *ngFor="let column of columns">
                    <th
                        class="p-1"
                        [attr.colspan]="column.colspan"
                        [class]="getHeaderClass(column)"
                        [ngStyle]="{ width: column.width ? column.width : 'auto' }"
                        [ngClass]="{ 'cursor-pointer': column.sort !== undefined && column.sort !== false }"
                        matTooltipPosition="above"
                        (click)="sortColumn(column)"
                        *ngIf="column.name !== null"
                    >
                        <i
                            [hidden]="!column.sort"
                            [class.ph-sort-descending]="column.sort == 'asc'"
                            [class.ph-sort-ascending]="column.sort == 'desc'"
                        ></i>
                        {{ column.name }}
                    </th>
                </ng-container>

                <ng-container *ngIf="create && !showOnlyEdit">
                    <th class="plus-container print-none p-1 text-center" style="min-width: 40px">
                        <button
                            mat-button
                            color="accent"
                            (click)="createItem(createModalContent)"
                            class="plus button-small"
                        >
                            <i class="fa fa-plus fa-lg"></i>
                        </button>
                    </th>
                </ng-container>

                <!-- <ng-container *ngIf="!showOnlyEdit">
          <th *ngIf="delete || edit || create " class="text-center plus-container print-none p-1" style="min-width: 40px;">
            <ng-container *ngIf="!(search || filters.length > 0)">
              <button mat-button color="accent" (click)="createItem(createModalContent)" class="plus button-small">
                <i class="far fa-plus fa-lg"></i>
              </button>
            </ng-container>
          </th>
        </ng-container> -->

                <ng-container *ngIf="showOnlyEdit">
                    <th class="plus-container print-none p-1 text-center" style="min-width: 40px"></th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataTable.data">
                <!-- <td *ngIf="select" [ngClass]="{ 'interactive-off': !isSelected(item) }" class="interactive p-1"
            (click)="toggleSelectedItem(item)">
          <i class="far" [ngClass]="{ 'fa-check-square': isSelected(item), 'fa-square': !isSelected(item) }"></i>
        </td> -->

                <td
                    *ngFor="let column of columns"
                    class="p-1"
                    [class]="getClass(column, item)"
                    [style]="getStyle(column, item)"
                    (click)="getCallback(column, item)"
                >
                    <!-- <div *ngIf="!column.route && !column.content" [innerHTML]="column.trustHtml ? sanitize.bypassSecurityTrustHtml(getValue(column, item)) : getValue(column, item)"></div> -->

                    <ng-container *ngIf="!column.content">
                        <div
                            *ngIf="!column.type"
                            [class]="getClass(column, item)"
                            [innerHTML]="
                                column.trustHtml
                                    ? sanitize.bypassSecurityTrustHtml(getValue(column, item))
                                    : getValue(column, item)
                            "
                        ></div>

                        <ng-container *ngIf="column.type">
                            <container-element [ngSwitch]="column.type">
                                <ng-container *ngSwitchCase="'image'">
                                    <div class="interactive" (click)="openImageDialogModal(getValue(column, item))">
                                        <i class="ph ph-image"></i>
                                    </div>
                                </ng-container>
                            </container-element>

                            <div
                                *ngIf="!column.type"
                                [innerHTML]="
                                    column.trustHtml
                                        ? sanitize.bypassSecurityTrustHtml(getValue(column, item))
                                        : getValue(column, item)
                                "
                            ></div>
                        </ng-container>
                    </ng-container>

                    <!-- <a *ngIf="column.route" class="interactive" [routerLink]="getRoute(column, item, 'routerLink')"
            [queryParams]="getRoute(column, item, 'queryParams')"
            [innerHTML]="column.trustHtml ? sanitize.bypassSecurityTrustHtml(getValue(column, item)) : getValue(column, item)"></a> -->
                    <div *ngIf="column.content">
                        <ng-container
                            *ngTemplateOutlet="column.content.template; context: column.content.context(item)"
                        >
                        </ng-container>
                    </div>
                </td>
                <td *ngIf="create || showOnlyEdit" class="buttons print-none p-1">
                    <button
                        *ngIf="edit"
                        mat-button
                        color="accent"
                        class="button-small"
                        (click)="editItem(createModalContent, item)"
                    >
                        <i class="ph ph-pencil-simple-line"></i>
                    </button>
                    <!-- <button mat-button color="warn" class="button-small" *ngIf="showDeleteButtonPerRow" (click)="deleteItem(item)">
            <i class="far fa-trash-alt"></i></button> -->
                </td>
            </tr>
        </tbody>

        <!-- <tfoot *ngIf="pagination" [hidden]="columns.length <= 0">
            <tr class="print-none">
                <td [attr.colspan]="columns.length + 1">
                    <mfBootstrapPaginator [rowsOnPageSet]="itemsPerPageSelect"></mfBootstrapPaginator>
                </td>
            </tr>
        </tfoot> -->
        <!--
    <tfoot *ngIf="pagination && totalItems" [hidden]="columns.length <= 0">
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <ul class="pagination" [hidden]="totalItems <= itemsPerPage">
            <li class="page-item" [ngClass]="{ 'disabled': pages[0] == page }">
              <a class="page-link" style="cursor: pointer;" (click)="changePage(1)">«</a>
            </li>

            <li *ngFor="let item of pages" class="page-item" [ngClass]="{ 'active': item == page }">
              <a class="page-link" style="cursor: pointer;" (click)="changePage(item)">{{ item }}</a>
            </li>

            <li class="page-item" [ngClass]="{ 'disabled': pages[pages.length - 1] == page }">
              <a class="page-link" style="cursor: pointer;" (click)="changePage(-1)">»</a>
            </li>
          </ul>
          <ul *ngIf="itemsPerPageSelect[0] < totalItems" class="pagination pull-right float-sm-right">
            <li *ngFor="let item of itemsPerPageSelect" class="page-item" [ngClass]="{ 'active': item == itemsPerPage }" >
              <a class="page-link" style="cursor: pointer;" (click)="changeLimit(item)">{{ item }}</a>
            </li>
          </ul>
        </td>
      </tr>
    </tfoot> -->
    </table>
    <div *ngIf="pagination" [hidden]="columns.length <= 0">
        <app-paginator
            class="print-none"
            [columns]="columns"
            [page]="page"
            [totalItems]="totalItems ? totalItems : data.length"
            (pageChanged)="changePage($event)"
            (limitChanged)="changeLimit($event)"
        >
        </app-paginator>
    </div>
</div>

<!-- modal: create -->
<ng-template #createModalContent>
    <div class="mb-4 text-lg font-semibold text-[#8E91D1]">
        <span>{{ getFormTitle() }}</span>
    </div>
    <app-table-form #createModalForm [form]="form" [formElements]="formElements"> </app-table-form>
    <app-modal-footer
        [isFormValid]="createModalForm.isValid()"
        [showDelete]="delete && createModalForm.getValue().id"
        [showSave]="!(formElements.length == 1 && formElements[0].type == 'dropzone')"
        (closed)="close()"
        (saved)="itemCreated(createModalForm.getValue())"
        (deleted)="deleteItem(createModalForm.getValue())"
    >
    </app-modal-footer>
</ng-template>
