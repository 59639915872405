import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-manage-school',
    templateUrl: './manage-school.component.html',
    styleUrls: ['./manage-school.component.css'],
})
export class ManageSchoolComponent implements OnInit {
    tabs = [
        {
            name: 'Projects',
            active: false,
            selector: 'projects',
            visible: true,
        },
        {
            name: 'Invitations',
            active: false,
            selector: 'invitations',
            visible: true,
        },
    ];

    activeTab: any;

    votesTopics: any[] = [];
    isLoading: boolean = true;
    storageUrl: string = environment.storageUrl;
    panelOpenState = false;

    constructor(public sessionService: SessionService) {}

    ngOnInit() {
        // this.fetch();
    }

    get schoolName() {
        return this.sessionService.getCurrentUserSchool() ? this.sessionService.getCurrentUserSchool().name : '';
    }

    setTab(tab: any) {
        this.activeTab = tab;
    }
}
