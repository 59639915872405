<div class="flex gap-4">
    <button class="border-gradient border-gradient-purple h-48 w-60 px-4 py-2">test 1</button>
    <div class="box h-48 w-60 px-4 py-3">test 3</div>
</div>

<div class="mt-4 grid grid-cols-1 gap-4 xl:grid-cols-12">
    <app-card class="col-span-6" [paddingX]="false" [paddingY]="false" [gradientBorder]="true">
        <div class="p-2">
            <img
                class="h-[160px] w-full max-w-full rounded-lg object-cover"
                src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                alt=""
            />
            <div class="mt-3 flex flex-col gap-2">
                <span>gradient border</span>
                <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
            </div>
        </div>
    </app-card>

    <app-card class="col-span-6" [paddingX]="false" [paddingY]="false">
        <div class="p-2">
            <img
                class="h-[160px] w-full max-w-full rounded-lg object-cover"
                src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                alt=""
            />
            <div class="mt-3 flex flex-col gap-2">
                <span>no gradient border</span>
                <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
            </div>
        </div>
    </app-card>
</div>
