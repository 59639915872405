import { settings } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { WelcomeStudentDialogComponent } from '../questionnaire/welcome-student-dialog/welcome-student-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/services/session.service';
import { HomeService } from '../../services/home.service';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    animations: [flyInOut, flyRigthIn, flyleftIn],
})
export class HomepageComponent implements OnInit {
    isLoading: boolean = false;
    stats;

    constructor(
        private title: Title,
        private dialog: MatDialog,
        private session: SessionService,
        private homeService: HomeService,
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Home');
        if (this.session.getQuestionnairePopUp() && !this.session.getCurrentUser().is_admin) {
            this.openDialog();
        }

        this.fetch();
    }

    openDialog() {
        this.dialog.open(WelcomeStudentDialogComponent, {
            data: {
                project: this.session.getCurrentUserProject(),
            },
            disableClose: true,
            closeOnNavigation: true,
            backdropClass: 'start-pop-up',
        });
    }

    fetch() {
        this.homeService.getAll().subscribe({
            next: ({ data }: any) => {
                console.log(data);
                this.stats = data.stats;
            },
        });
    }
}
