<!-- <div class="flex gap-4 my-3">
    <app-select
        *ngIf="teams.length > 0"
        class="w-[240px]"
        [placeholder]="'Team'"
        [options]="teams"
        [optionValueSelector]="'id'"
        [optionTextSelector]="'name'"
        [optionEmpty]="true"
        [optionEmptyValue]="null"
        [optionEmptyText]="'-'"
        [(ngModel)]="selectedTeamId"
        (ngModelChange)="getTeam()"
    >
    </app-select>

    <app-select
        *ngIf="selectedTeam"
        class="w-[240px]"
        [placeholder]="'User'"
        [options]="selectedTeam.members.students"
        [optionValueSelector]="'id'"
        [optionTextSelector]="'nickname'"
        [optionEmpty]="true"
        [optionEmptyValue]="null"
        [optionEmptyText]="'-'"
        [(ngModel)]="selectedStudentId"
        (ngModelChange)="getStudentChartData()"
    >
    </app-select>
</div> -->

<app-card class="my-2 w-full" [paddingX]="false" [paddingY]="false">
    <div class="flex items-center gap-4">
        <div class="text-[#8E91D1] text-lg font-light px-3 my-4">
            <span>{{ today | date: 'EEEE' }}, </span>
            <span class="text-[#8e91d173]">{{ today | date: ' MMMM d' }}</span>
        </div>
        <app-select
            *ngIf="teams.length > 0"
            class="w-[240px]"
            [placeholder]="'Team'"
            [options]="teams"
            [optionValueSelector]="'id'"
            [optionTextSelector]="'name'"
            [optionEmpty]="true"
            [optionEmptyValue]="null"
            [optionEmptyText]="'-'"
            [(ngModel)]="selectedTeamId"
            (ngModelChange)="getTeam()"
        >
        </app-select>

        <app-select
            *ngIf="selectedTeam"
            class="w-[240px]"
            [placeholder]="'User'"
            [options]="selectedTeam.members.students"
            [optionValueSelector]="'id'"
            [optionTextSelector]="'nickname'"
            [optionEmpty]="true"
            [optionEmptyValue]="null"
            [optionEmptyText]="'-'"
            [(ngModel)]="selectedStudentId"
            (ngModelChange)="getStudentChartData()"
        >
        </app-select>
    </div>

    <table class="table table-striped mb-0 w-full">
        <tbody>
            <tr>
                <td>
                    <div class="flex justify-around">
                        <div class="text-center">
                            <div class="text-2xl 2xl:text-3xl">
                                <span numberAnimation [numberValue]="currentStats.completed_tasks">
                                    {{ currentStats.completed_tasks }}
                                </span>
                            </div>
                            <div class="text-[#8E91D1] text-xs font-light">
                                <span>{{ 'communications.info.completed_tasks' | translate }}</span>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="text-2xl 2xl:text-3xl">
                                <span numberAnimation [numberValue]="currentStats.uncompleted_tasks">
                                    {{ currentStats.uncompleted_tasks }}
                                </span>
                            </div>
                            <div class="text-[#8E91D1] text-xs font-light">
                                <span>{{ 'communications.info.uncompleted_tasks' | translate }}</span>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="text-2xl 2xl:text-3xl">
                                <span
                                    numberAnimation
                                    [numberValue]="
                                        calculatePercentage(
                                            currentStats.completed_tasks,
                                            currentStats.uncompleted_tasks
                                        )
                                    "
                                >
                                    {{
                                        calculatePercentage(
                                            currentStats.completed_tasks,
                                            currentStats.uncompleted_tasks
                                        )
                                    }}
                                </span>
                            </div>
                            <div class="text-[#8E91D1] text-xs font-light">
                                <span>{{ 'communications.info.completed_tasks' | translate }} %</span>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="flex justify-center">
                        <div class="flex gap-6">
                            <div class="text-center">
                                <div numberAnimation [numberValue]="currentStats.documents">
                                    {{ currentStats.documents }}
                                </div>
                                <span class="text-[#8E91D1] text-xs font-light">
                                    {{ 'communications.info.documents' | translate }}
                                </span>
                            </div>
                            <div class="text-center">
                                <div numberAnimation [numberValue]="currentStats.time">
                                    {{ currentStats.time }}
                                </div>
                                <span class="text-[#8E91D1] text-xs font-light">
                                    {{ 'communications.info.time' | translate }} (mins)
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</app-card>

<!-- <div class="flex max-w-md justify-between">
    <app-card class="w-24 h-24" [paddingX]="false" [paddingY]="false">
        <div class="flex flex-col items-start justify-center gap-1.5 p-2">
            <div class="text-[#8E91D1] text-xs font-light">
                <span>{{ 'communications.info.completed_tasks' | translate }}</span>
            </div>
            <div class="text-3xl 2xl:text-4xl mx-auto">
                <span>{{ currentStats.completed_tasks }}</span>
            </div>
        </div>
    </app-card>

    <app-card class="w-24 h-24" [paddingX]="false" [paddingY]="false">
        <div class="flex flex-col items-start justify-center gap-1.5 p-2">
            <div class="text-[#8E91D1] text-xs font-light">
                <span>{{ 'communications.info.documents' | translate }}</span>
            </div>
            <div class="text-3xl 2xl:text-4xl mx-auto">
                <span>{{ currentStats.documents }}</span>
            </div>
        </div>
    </app-card>

    <app-card class="w-24 h-24" [paddingX]="false" [paddingY]="false">
        <div class="flex flex-col items-start justify-center gap-1.5 p-2">
            <div class="text-[#8E91D1] text-xs font-light">
                <span>{{ 'communications.info.time' | translate }}</span>
            </div>
            <div class="text-3xl 2xl:text-4xl mx-auto">
                <span>{{ currentStats.time }}</span>
            </div>
        </div>
    </app-card>

    <app-card class="w-24 h-24" [paddingX]="false" [paddingY]="false">
        <div class="flex flex-col items-start justify-center gap-1.5 p-2">
            <div class="text-[#8E91D1] text-xs font-light">
                <span>{{ 'communications.info.uncompleted_tasks' | translate }}</span>
            </div>
            <div class="text-3xl 2xl:text-4xl mx-auto">
                <span>{{ currentStats.uncompleted_tasks }}</span>
            </div>
        </div>
    </app-card>
</div> -->

<div *ngIf="userCharts.inited && selectedStudentId" class="grid grid-cols-1 gap-4 mt-4">
    <app-card
        class="col-span-1 xl:col-span-1"
        [title]="'communications.info.user_chart_name' | translate"
        [expandable]="true"
    >
        <app-chart-loader *ngIf="isLoading"></app-chart-loader>

        <div class="flex gap-3">
            <highcharts-chart
                *ngIf="!isLoading"
                class="block my-3 h-64"
                [Highcharts]="userCharts.tasks.highcharts"
                [options]="userCharts.tasks.options"
                [(update)]="userCharts.tasks.updateFlag"
            ></highcharts-chart>
            <highcharts-chart
                *ngIf="!isLoading"
                class="block my-3 h-64"
                [Highcharts]="userCharts.documents.highcharts"
                [options]="userCharts.documents.options"
                [(update)]="userCharts.documents.updateFlag"
            ></highcharts-chart>
            <highcharts-chart
                *ngIf="!isLoading"
                class="block my-3 h-64"
                [Highcharts]="userCharts.time.highcharts"
                [options]="userCharts.time.options"
                [(update)]="userCharts.time.updateFlag"
            ></highcharts-chart>
        </div>
    </app-card>
</div>

<div class="grid grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
    <app-card
        class="col-span-1 xl:col-span-1"
        [title]="'communications.info.time_chart_name' | translate"
        [expandable]="true"
    >
        <app-chart-loader *ngIf="isLoading"></app-chart-loader>

        <highcharts-chart
            *ngIf="!isLoading"
            class="block my-3 h-64"
            [Highcharts]="timeChart.highcharts"
            [options]="timeChart.options"
        ></highcharts-chart>
    </app-card>
</div>

<div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
    <app-card
        class="col-span-1 xl:col-span-1"
        [title]="'communications.info.tasks_chart_name' | translate"
        [expandable]="true"
    >
        <app-chart-loader *ngIf="isLoading"></app-chart-loader>

        <highcharts-chart
            *ngIf="!isLoading"
            class="block my-3 h-64"
            [Highcharts]="tasksChart.highcharts"
            [options]="tasksChart.options"
            [(update)]="tasksChart.updateFlag"
        ></highcharts-chart>
    </app-card>

    <app-card
        class="col-span-1 xl:col-span-1"
        [title]="'communications.info.documents_chart_name' | translate"
        [expandable]="true"
    >
        <app-chart-loader *ngIf="isLoading"></app-chart-loader>

        <highcharts-chart
            *ngIf="!isLoading"
            class="block my-3 h-64"
            [Highcharts]="documentsChart.highcharts"
            [options]="documentsChart.options"
        ></highcharts-chart>
    </app-card>
</div>
