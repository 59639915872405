<div class="flex h-full">
    <div class="w-full h-full">
        <app-un-tabs [tabs]="tabs" (tabChanged)="setTab($event)">
            <div header class="flex flex-1 items-start" style="gap: 48px">
                <div class="w-full">
                    <app-un-gradient-text [text]="schoolName" size="big"></app-un-gradient-text>
                </div>
            </div>

            <ng-container body>
                <app-un-tab *ngIf="activeTab.selector === 'projects'">
                    <div class="my-4">
                        <app-manage-projects></app-manage-projects>
                    </div>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.selector === 'invitations'">
                    <div class="my-4">
                        <app-manage-invitations></app-manage-invitations>
                    </div>
                </app-un-tab>
            </ng-container>
        </app-un-tabs>
    </div>
</div>
