<div class="mt-5 grid grid-cols-1 items-center lg:grid-cols-12">
    <div class="col-span-1 lg:col-span-3">
        <div class="flex justify-center">
            <div class="w-85 text-start">
                <div [@flyleftIn] class="mb-4">
                    <app-un-gradient-text [text]="'dashboard.be_a_part_of_community_part_one' | translate" size="big">
                    </app-un-gradient-text>
                    <app-un-gradient-text [text]="'dashboard.be_a_part_of_community_part_two' | translate" size="big">
                    </app-un-gradient-text>
                </div>
                <p [@flyInOut] class="gray-text poppins-light mb-5">
                    {{ 'dashboard.description' | translate }}
                </p>
            </div>
        </div>
    </div>
    <div [@flyRigthIn] class="col-span-1 flex justify-center lg:col-span-9">
        <div class="gradient-border welcome w-85">
            <img src="/assets/home.jpg" style="max-width: 100%; width: 100%" alt="" />
        </div>
    </div>
</div>

<!--
<div class="mt-4">
    <app-un-gradient-text [text]="'dashboard.be_a_part_of_community_part_one' | translate" size="big">
    </app-un-gradient-text>
    <app-un-gradient-text [text]="'dashboard.be_a_part_of_community_part_two' | translate" size="big">
    </app-un-gradient-text>
</div>

<div class="mt-4 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <app-card class="col-span-1">
        <span class="task-chip border-yellow-100 text-yellow-100">
            <i class="ph ph-identification-badge"></i> Personal
        </span>
        <div class="mt-3 flex justify-between">
            <span>Completed tasks </span>
            <span>{{ stats?.completed_tasks.done }} / {{ stats?.completed_tasks.total }}</span>
        </div>
    </app-card>

    <app-card class="col-span-1">
        <span class="task-chip border-yellow-100 text-yellow-100">
            <i class="ph ph-identification-badge"></i> Team
        </span>
        <div class="mt-3 flex justify-between">
            <span>Place among the teams </span>
            <span>{{ stats?.place_among_the_team.place }} / {{ stats?.place_among_the_team.total }}</span>
        </div>
    </app-card>

    <app-card class="col-span-1">
        <span class="task-chip border-yellow-100 text-yellow-100">
            <i class="ph ph-identification-badge"></i> School
        </span>
        <div class="mt-3 flex justify-between">
            <span>Total participants </span>
            <span>{{ stats?.total_participants }}</span>
        </div>
    </app-card>
    <app-card class="col-span-1">
        <span class="task-chip border-yellow-100 text-yellow-100">
            <i class="ph ph-identification-badge"></i> Public
        </span>
        <div class="mt-3 flex justify-between">
            <span>Completed projects </span>
            <span>{{ stats?.completed_projects }}</span>
        </div>
    </app-card>
</div>

<div class="mt-4 grid grid-cols-2 gap-4 md:grid-cols-4">
    <div class="grid gap-4">
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <img
                    class="h-auto max-w-full rounded-lg"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg"
                    alt=""
                />
                <div class="mt-3 flex flex-col gap-2">
                    <span>News</span>
                    <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
                </div>
            </div>
        </app-card>
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <div class="mt-3 flex flex-col gap-2">
                    <span>Personal Task</span>
                    <span class="text-[#8E91D1]">Team Buildin Quick Guide</span>
                </div>
            </div>
        </app-card>
    </div>

    <div class="grid gap-4">
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <img
                    class="h-[160px] w-full max-w-full rounded-lg object-cover"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                    alt=""
                />
                <div class="mt-3 flex flex-col gap-2">
                    <span>Project Technology</span>
                    <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
                </div>
            </div>
        </app-card>
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <img
                    class="h-[160px] w-full max-w-full rounded-lg object-cover"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                    alt=""
                />
                <div class="mt-3 flex flex-col gap-2">
                    <span>Project Technology</span>
                    <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
                </div>
            </div>
        </app-card>
    </div>

    <div class="grid gap-4">
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <img
                    class="h-[160px] w-full max-w-full rounded-lg object-cover"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                    alt=""
                />
                <div class="mt-3 flex flex-col gap-2">
                    <span>Project Technology</span>
                    <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
                </div>
            </div>
        </app-card>
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <img
                    class="h-[160px] w-full max-w-full rounded-lg object-cover"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                    alt=""
                />
                <div class="mt-3 flex flex-col gap-2">
                    <span>Project Technology</span>
                    <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
                </div>
            </div>
        </app-card>
    </div>

    <div class="grid gap-4">
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <img
                    class="h-auto max-w-full rounded-lg"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg"
                    alt=""
                />
                <div class="mt-3 flex flex-col gap-2">
                    <span>News</span>
                    <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
                </div>
            </div>
        </app-card>
        <app-card [paddingX]="false" [paddingY]="false">
            <div class="p-2">
                <div class="mt-3 flex flex-col gap-2">
                    <span>Personal Task</span>
                    <span class="text-[#8E91D1]">Team Buildin Quick Guide</span>
                </div>
            </div>
        </app-card>
    </div>
</div> -->
